<template>
	<v-card>
		<v-card-title>탈퇴 안내</v-card-title>
		<v-card-text>
			<p>회원탈퇴를 신청하기 전에 안내 사항을 꼭 확인해주세요.</p>
			<v-list>
				<v-list-item two-line class="px-0">
					<v-list-item-icon>
						<v-icon color="success">mdi-check</v-icon>
					</v-list-item-icon>
					<v-list-item-title class="text-wrap" style="line-height: 23px">
						<strong>
							사용하고 계신 계정(
							<span class="primary--text">{{ profile.email }}</span>
							)은 탈퇴할 경우 재사용 및 복구가 불가능합니다.
						</strong>
						<br />
						<span class="error--text">
							탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가
						</span>
						하오니 신중하게 선택하시기 바랍니다.
					</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-list>
				<v-list-item three-line class="px-0">
					<v-list-item-icon>
						<v-icon color="success">mdi-check</v-icon>
					</v-list-item-icon>
					<v-list-item-title class="text-wrap" style="line-height: 23px">
						<strong>
							탈퇴 후 회원정보 및 서비스 이용기록은 모두 삭제됩니다.
						</strong>
						<br />
						회원정보 및 교육, 자격증, 이벤트 등 서비스 이용기록은 모두 삭제되며,
						삭제된 데이터는 복구되지 않습니다.
						<br />
						삭제되는 내용을 확인하시고 필요한 데이터는 미리 백업을 해주세요.
					</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-list class="pl-9">
				<v-list-item class="px-0">
					<v-list-item-title class="text-wrap">
						<p>탈퇴 후 회원정보 및 서비스 이용기록은 모두 삭제됩니다.</p>
						<table
							cellspacing="0"
							border="1"
							summary="탈퇴 후 회원정보 및 서비스 이용기록 삭제 안내"
							class="tbl_type"
						>
							<caption></caption>
							<tbody>
								<tr style="display: table-row">
									<th scope="row">교육</th>
									<td>교육 신청 및 결제 내역 삭제</td>
								</tr>
								<tr style="display: table-row">
									<th scope="row">자격증</th>
									<td>교육 이수후 발급된 자격증 발급 내역 삭제</td>
								</tr>
								<tr style="display: table-row">
									<th scope="row">이벤트</th>
									<td>이벤트 신청 및 결제 내역 삭제</td>
								</tr>
							</tbody>
						</table>
					</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-list>
				<v-list-item two-line class="px-0">
					<v-list-item-icon>
						<v-icon color="success">mdi-check</v-icon>
					</v-list-item-icon>
					<v-list-item-title class="text-wrap" style="line-height: 23px">
						<strong>
							IT 마케터 계정을 사용해 다른 서비스에 로그인 하거나 이용할 수 없게
							됩니다.
						</strong>
						<br />
						IT 마케터 계정으로 로그인하여 사용 중인 다른 관련 서비스들은 모두
						탈퇴 처리되며 다시 이용할 수 없습니다.
					</v-list-item-title>
				</v-list-item>
			</v-list>
			<v-divider />
			<p class="error--text mt-6">
				탈퇴 후에는 계정
				<span class="primary--text font-weight-bold">{{ profile.email }}</span>
				로 다시 가입할 수 없으며 아이디와 데이터는 복구할 수 없습니다.
				<br />
				탈퇴 후 IT 마케터 자격증은 더 이상 유효하지 않습니다.
				<br />
				또한, IT 마케터 계정을 사용해 다른 서비스에 로그인 하거나 이용할 수 없게
				됩니다.
			</p>
			<div class="d-flex align-center">
				<v-checkbox v-model="checkbox" />
				안내 사항을 모두 확인하였으며, 이에 동의합니다.
			</div>
		</v-card-text>
		<v-card-actions class="d-flex justify-center">
			<v-btn color="secondary" :disabled="!checkbox" @click="deactivateUser">
				확인
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const checkbox = ref(false)
		const {
			publicPictureUrl,
			email,
			emailVerified,
			uid,
			name,
			birthDate,
			phoneNumber,
			phoneNumberVerified,
			recommenderReferralCode,
		} = store.getters['user/getMeDetail']

		const profile = ref({
			publicPictureUrl,
			email,
			emailVerified,
			uid,
			name,
			birthDate,
			phoneNumber,
			phoneNumberVerified,
			recommenderReferralCode,
		})

		const deactivateUser = async () => {
			try {
				const confirm = await confirmSwal(
					'회원탈퇴를 진행하시겠습니까?<br />탈퇴한 아이디는 본인과 타인 모두<br /> <strong style="color: red;">재사용 및 복구가 불가</strong>합니다.',
				)
				if (confirm.isConfirmed) {
					await AuthService.deactivateUser()
					const confirm = await successSwal({
						html: '회원 탈퇴 처리되었습니다',
						allowOutsideClick: false,
					})
					if (confirm.isConfirmed) {
						await AuthService.signout()
						router.push({ name: 'main' })
					}
				}
			} catch (e) {
				warningSwal('내 정보를 저장하는데 문제가 발생했습니다')
			}
		}

		return {
			profile,
			checkbox,

			deactivateUser,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list {
	.v-list-item__icon {
		margin-top: 0;
	}
}
table {
	font-size: 15px;
	position: relative;
	width: 100%;
	margin: 11px auto 0;
	table-layout: fixed;
	border-spacing: 0;
	border-collapse: collapse;
	color: #333;
	border: 0;
	border-top: 1px solid #ededed;
	overflow: scroll;
	overflow: auto;

	th {
		width: 100px;
		font-weight: 400;
		padding-left: 21px;
		padding-top: 16px;
		padding-bottom: 16px;
		text-align: left;
		letter-spacing: -1px;
		border-right: 1px solid #ededed;
		color: #777;
		border-left: 0;
		border-bottom: 1px solid #ededed;
	}

	td {
		padding-left: 19px;
		padding-top: 16px;
		padding-bottom: 16px;
		color: #777;
		border-right: 0;
		border-bottom: 1px solid #ededed;
	}
}
</style>
